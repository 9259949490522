<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    client: {
      name: { required },
      email: { required },
      cellphone: { required },
      zipcode: {},
      address: { required },
      number: { required },
      complement: {},
      district: { required },
      city: { required },
      state: { required },
    }
  },
  data() {
    return {
      client: {
        loading: false,

        name: "",
        person_type: 1,
        person_number: "",
        email: "",
        cellphone: "",
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
      }
    };
  },
  methods: {
    getCliente() {
      api
        .get("clientes/" + this.$route.params.id)
        .then((res) => {
          if (res.data.status == "success") {
            this.client.name = res.data.cliente.name;
            this.client.person_type = res.data.cliente.person_type;
            this.client.person_number = res.data.cliente.person_number;
            this.client.email = res.data.cliente.email;
            this.client.cellphone = res.data.cliente.cellphone;
            this.client.zipcode = res.data.cliente.zipcode;
            this.client.address = res.data.cliente.address;
            this.client.number = res.data.cliente.number;
            this.client.complement = res.data.cliente.complement;
            this.client.district = res.data.cliente.district;
            this.client.city = res.data.cliente.city;
            this.client.state = res.data.cliente.state;
          } else {
            this.$router.push("/clientes");
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/clientes");
          }
        });
    },
    editarCliente() {
      console.log(this.client.bank);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.client.loading = true;

        api
          .post("clientes/" + this.$route.params.id, {
            name: this.client.name,
            email: this.client.email,
            cellphone: this.client.cellphone,
            zipcode: this.client.zipcode,
            address: this.client.address,
            number: this.client.number,
            complement: this.client.complement,
            district: this.client.district,
            city: this.client.city,
            state: this.client.state,
          })
          .then((res) => {
            if (res.data.status == "success") {
              this.$v.$reset();

              this.$router.push('/clientes');
              this.$toast.success('O cliente foi editado com sucesso.');
            } else {
              this.$toast.error(res.data.message);
            }

            this.client.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getCliente();
  },
  watch: {
    person_type: function () {
      this.person_number = "";
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/clientes">Clientes</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Editar cliente</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Editar cliente</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-5">
            <b-form @submit.prevent="editarCliente">
              <b-form-group id="name" label="Nome Completo" label-for="name" class="required">
                <b-form-input id="name" v-model="client.name" type="text" :class="{ 'is-invalid': $v.client.name.$error }"></b-form-input>
                <div v-if="$v.client.name.$error" class="invalid-feedback">
                  <span v-if="!$v.client.name.required">O nome completo é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="person_type" label="Tipo de Pessoa" label-for="person_type" class="required">
                <b-form-input v-if="client.person_type == 1" id="person_type" class="bg-soft-primary" type="text" value="Pessoa Física" disabled></b-form-input>
                <b-form-input v-else id="person_type" class="bg-soft-primary" type="text" value="Pessoa Jurídica" disabled></b-form-input>
              </b-form-group>
              <template v-if="client.person_type == 1">
                <b-form-group id="person_number" label="CPF" label-for="person_number" class="required">
                  <b-form-input id="person_number" class="bg-soft-primary" v-model="client.person_number" type="text" disabled></b-form-input>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group id="person_number" label="CNPJ" label-for="person_number" class="required">
                  <b-form-input id="person_number" class="bg-soft-primary" v-model="client.person_number" type="text" disabled></b-form-input>
                </b-form-group>
              </template>
              <b-form-group id="email" label="E-mail" label-for="email">
                <b-form-input id="email" v-model="client.email" type="text" :class="{ 'is-invalid': $v.client.email.$error }"></b-form-input>
                <div v-if="$v.client.email.$error" class="invalid-feedback">
                  <span v-if="!$v.client.email.required">O e-mail é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="cellphone" label="Celular" label-for="cellphone">
                <b-form-input id="cellphone" v-model="client.cellphone" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.client.cellphone.$error }" v-mask="'(##) #####-####'"></b-form-input>
                <div v-if="$v.client.cellphone.$error" class="invalid-feedback">
                  <span v-if="!$v.client.cellphone.required">O celular é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="zipcode" label="CEP" label-for="zipcode">
                <b-form-input id="zipcode" v-model="client.zipcode" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.client.zipcode.$error }" v-mask="'#####-###'"></b-form-input>
                <div v-if="$v.client.zipcode.$error" class="invalid-feedback">
                  <span v-if="!$v.client.zipcode.required">O cep é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="address" label="Endereço" label-for="address">
                <b-form-input id="address" v-model="client.address" type="text" :class="{ 'is-invalid': $v.client.address.$error }"></b-form-input>
                <div v-if="$v.client.address.$error" class="invalid-feedback">
                  <span v-if="!$v.client.address.required">O endereço é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="number" label="Número" label-for="number">
                <b-form-input id="number" v-model="client.number" type="text" :class="{ 'is-invalid': $v.client.number.$error }"></b-form-input>
                <div v-if="$v.client.number.$error" class="invalid-feedback">
                  <span v-if="!$v.client.number.required">O número é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="complement" label="Complemento" label-for="complement">
                <b-form-input id="complement" v-model="client.complement" type="text" :class="{ 'is-invalid': $v.client.complement.$error }"></b-form-input>
                <div v-if="$v.client.complement.$error" class="invalid-feedback">
                  <span v-if="!$v.client.complement.required">O complmento é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="district" label="Bairro" label-for="district">
                <b-form-input id="district" v-model="client.district" type="text" :class="{ 'is-invalid': $v.client.district.$error }"></b-form-input>
                <div v-if="$v.client.district.$error" class="invalid-feedback">
                  <span v-if="!$v.client.district.required">O bairro é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="city" label="Cidade" label-for="city">
                <b-form-input id="city" v-model="client.city" type="text" :class="{ 'is-invalid': $v.client.city.$error }"></b-form-input>
                <div v-if="$v.client.city.$error" class="invalid-feedback">
                  <span v-if="!$v.client.city.required">A cidade é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="state" label="Estado" label-for="state">
                <b-form-input id="state" v-model="client.state" type="text" :class="{ 'is-invalid': $v.client.state.$error }"></b-form-input>
                <div v-if="$v.client.state.$error" class="invalid-feedback">
                  <span v-if="!$v.client.state.required">O estado é obrigatório.</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="this.client.loading == true || this.$v.$invalid" type="submit" variant="default">
                  Salvar
                  <b-spinner v-if="client.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>